// ** Redux Imports
import { combineReducers } from "redux";
// ** Reducers Imports
import auth from "./auth/authReducer";
import layout from "./layout";

const rootReducer = combineReducers({
    auth,
    layout,
});

export default rootReducer;
